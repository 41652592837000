/*
  Project: SafeRx
  Author: XFive
 */
/* eslint-disable no-unused-vars,func-names */
import jQuery from 'jquery';
import 'bootstrap';
import Swiper from 'swiper/swiper-bundle.min';

// import 'owl.carousel';
// import 'owl.carousel';

const $ = jQuery;

/* eslint-disable func-names */

$('.sidebar .links .link').click(function () {
  $('.sidebar').removeClass('toggle');
});

function headerHeight() {
  /* eslint-disable no-shadow */
  const headerHeight = $('.header-menu').outerHeight();
  const bannerHeight = $(window).height() - headerHeight;
  // const headerHeight = $('.banner').height('');
  $('.banner').css({ height: `${bannerHeight}px` });
  // $('.banner').css({'margin-top': headerHeight + 'px'})
}

if ($('.banner').length > 0) {
  headerHeight();
  /* eslint-disable func-names */
  $(window).resize(function () {
    headerHeight();
  });
}

// if ($('.slider-wrapper').length > 0) {
//   $('.slider-wrapper').owlCarousel({
//     items: 1,
//     loop: false,
//     dots: false,
//     nav: true,
//     navText: [
//       "<i class='fa fa-chevron-left'></i>",
//       "<i class='fa fa-chevron-right'></i>",
//     ],
//   });
// }

if ($('.mySwiper').length > 0) {
  const swiper = new Swiper('.mySwiper', {
    loop: true,
    autoHeight: true,
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.sw-button-next',
      prevEl: '.sw-button-prev',
    },
  });
}
/* eslint-disable no-undef */

if ($('.client-logo').length > 0) {
  const client = new Swiper('.client-logo-slider', {
    loop: true,
    autoHeight: false,
    spaceBetween: 30,
    pagination: false,
    navigation: {
      nextEl: '.sw-button-next',
      prevEl: '.sw-button-prev',
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoHeight: true,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  });
}
/* eslint-disable no-undef */

/* Sidebar Toggle */

const hamburgerMenu = document.getElementById('hamburger-icon');
/* eslint-disable no-unused-vars,func-names */
const closeBtn = document.getElementById('close');
/* eslint-disable no-unused-vars,func-names */
hamburgerMenu.addEventListener('click', function () {
  const sidebar = document.getElementById('sidebars');
  sidebar.classList.toggle('toggle');
});
/* eslint-disable no-unused-vars,func-names */
function closeNav() {
  const sidebar = document.getElementById('sidebars');
  sidebar.classList.toggle('toggle');
}
$('#close').on('click', function () {
  closeNav();
});

$(window).scroll(function () {
  const sticky = $('.header-menu');
  const scroll = $(window).scrollTop();

  if (scroll >= 100) sticky.addClass('header-sticky');
  else sticky.removeClass('header-sticky');
});

if ($('.team-toggle-detail').length > 0) {
  $('.team-btn').click(function () {
    $(this).text($(this).text() === 'CLOSE BIOS' ? 'LOAD BIOS' : 'CLOSE BIOS');
    $('.team-toggle-detail').slideToggle(1000);
  });
}

/* eslint-disable no-use-before-define */
if ($('.video-toggle-wrapper').length > 0) {
  const ppbutton = document.getElementById('vidbutton');
  ppbutton.addEventListener('click', playPause);
  const myVideo = document.getElementById('myvid');
  const myVideoposter = document.getElementById('videoposterimage');
  myVideoposter.style.display = 'block';
  myVideo.style.display = 'none';
  /* eslint-disable no-inner-declarations */
  function playPause() {
    if (myVideo.paused) {
      myVideo.play();
      ppbutton.innerHTML = 'Pause';
      myVideoposter.style.display = 'none';
      myVideo.style.display = 'block';
    } else {
      myVideo.pause();
    }
  }
  myVideo.addEventListener('ended', () => {
    ppbutton.innerHTML = 'CLICK HERE';
    myVideoposter.style.display = 'block';
    myVideo.style.display = 'none';
  });
}

if ($('.circle-container').length > 0) {
  /* Team Page hover effect */
  /* eslint-disable no-param-reassign */
  $('.circle-container li').each(function ($i) {
    $i += 1;
    $('.advisor-main-list ul').css('display', 'none');
    $(this).hover(
      function (e) {
        $(`.advisor-main-list ul:nth-child(${$i})`).fadeIn(300);
        $('.mid-shape-decor').fadeOut(100);
      },
      function () {
        $(`.advisor-main-list ul:nth-child(${$i})`).fadeOut(100);
        $('.mid-shape-decor').fadeIn(300);
      },
    );
  });
}
if ($('.horizontal-news').length > 0) {
  jQuery('.horizontal-news').slice(0, 8).removeClass('d-none');
  if (jQuery('.horizontal-news').length <= 8) {
    jQuery('.load-more-link').hide();
  }
  jQuery('.load-more-link').click(function (e) {
    e.preventDefault();
    jQuery('.horizontal-news:hidden').slice(0, 8).removeClass('d-none');

    if (jQuery('.horizontal-news:hidden').length === 0) {
      jQuery('.load-more-link').hide();
    }
  });
}
